/*eslint-disable no-eval */
import moment from "moment";
import bigDecimal from "js-big-decimal";
import { v4 as uuidv4 } from "uuid";
import * as service from "../services";
import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
/* eslint import/no-webpack-loader-syntax: off */
import matlIconCss from '!!raw-loader!../assets/styles/material-icons.css';
import styleCss from '!!raw-loader!../assets/styles/style.css';
import mainCss from '!!raw-loader!../assets/styles/main.css';
import printCss from '!!raw-loader!../assets/styles/print.css';

export const startLoading = () => {
  const isTokenExpired = localStorage.getItem("isTokenExpired") || false;
  if (!isTokenExpired)
    if (document.getElementById("loader")) document.getElementById("loader").style.display = "block";
};

export const stopLoading = () => {
  if (document.getElementById("loader")) document.getElementById("loader").style.display = "none";
};

export const isEmpty = (obj) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return true;
};

export const validateEmail = (email) => {
  let pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  return pattern.test(email);
};

export const arrayToMap = (data, key) => {
  let map = {};
  data.map((item) => {
    let _key = item[key];
    if (_key in map) {
      map[_key].push(item);
    } else {
      map[_key] = [item];
    }
    return undefined;
  });
  return map;
};

export const randomString = (length) => {
  let result = [];
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&*";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
};

export const copyToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const myPermissions = (permissions, value) => {
  if (permissions)
    return permissions.filter(
      (item) => item.menu_id === value || item.object_name === value
    )[0];
  else return {};
};

export const myEsignSettings = (settings, value) => {
  if (settings)
    return settings.filter(
      (item) => item.menu_id === value || item.menu_name === value
    )[0];
  else return {};
};
export const myEmailSettings = (settings, value) => {
  if (settings)
    return settings.filter(
      (item) => item.menu_id === value || item.menu_name === value
    )[0];
  else return {};
};

export const getDistinctValArray = (data, key) => {
  return [...new Map(data.map((item) => [item[key], item])).values()];
};

export const removeDuplicates = (data) => {
  return data.filter((item, index) => data.indexOf(item) === index);
};

export const sortByIntVal = (data, key) => {
  return data.sort((a, b) => a[key] - b[key]);
};

export const getMagicNumber = () => {
  let min = 1;
  let max = 100;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const evalExpression = (expression) => {
  try {
    eval(expression);
    return "SUCCESS";
  } catch (e) {
    console.warn(e);
    return "ERROR";
  }
};

export const checkFileSize = (_files) => {
  let filesize = 0;
  let checkIfAllowedFilesize = true;
  for (let i = 0; i < _files.length; i++) {
    if (_files[i].size >= 1024) {
      filesize = Math.ceil(_files[i].size / 1024);
      if (filesize > 10240) {
        checkIfAllowedFilesize = false;
      }
    }
  }
  return checkIfAllowedFilesize;
};

export const replaceSpecialChars = (_str) => {
  let fname = "";
  let splits = _str.split(".");
  for (let i = 0; i < splits.length - 1; i++) {
    fname = fname + splits[i];
  }
  let fupname = fname.replace(/[&/#,+()$~%.'":*?<>{}\s+]/g, "_");
  let finalName = fupname + "." + splits[splits.length - 1];
  return finalName;
};

export const getMonthNumber = (monthName) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  //const monthNames = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  let ret = 0;
  monthNames.map((item, index) => {
    if (item === monthName) ret = index + 1;
    return item;
  });
  return ret;
};

export const getdate = (_str) => {
  let temp = undefined;
  let _date = undefined;
  let date_str = _str.trim();
  temp = date_str.split("-");
  _date = temp[2] + "-" + getMonthNumber(temp[1]) + "-" + temp[0];
  _date = new Date(_date);
  return _date;
};

export const customLookup = (_str, no) => {
  let _Lookup = [];
  for (let i = 1; i <= no; i++) {
    _Lookup.push({ value: i, label: _str + " - " + i });
  }
  return _Lookup;
};

export const getNow = (_format) => {
  return moment(new Date()).format(_format ? _format : "DD-MMM-yyyy hh:mm A");
};
export const genRandomUUID = () => {
  // Generates a UUID v4
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};
export const convertUTCtoIST = (date, format) => {
  try {
    let gmtDateTime = moment.utc(date, format ? format : "DD-MMM-yyyy HH:mm")
    return gmtDateTime.local().format(format ? format : 'DD-MMM-yyyy hh:mm A');
  } catch (e) {
    console.warn(e);
  }
};

export const formatCurrency = (price, decimal) => {
  try {
    return bigDecimal.getPrettyValue(bigDecimal.round(price, decimal || 0)).replace(/,/g, '');
  } catch (e) {
    console.warn(e);
  }
};

export const standardDeviation = (arr, decimal) => {
  let mean = arr.reduce((a, b) => a + b) / arr.length;
  arr = arr.map((k) => (k - mean) ** 2);
  let sum = arr.reduce((acc, curr) => acc + curr, 0);
  return bigDecimal.getPrettyValue(
    bigDecimal.round(Math.sqrt(sum / (arr.length - 1)), decimal || 0)
  );
};

export const relativeStandardDeviation = (arr, decimal) => {
  let mean = arr.reduce((a, b) => a + b) / arr.length;
  arr = arr.map((k) => (k - mean) ** 2);
  let sum = arr.reduce((acc, curr) => acc + curr, 0);
  let stdDeviation = Math.sqrt(sum / (arr.length - 1));
  return bigDecimal.getPrettyValue(
    bigDecimal.round(stdDeviation * (100 / mean), decimal || 0)
  );
};

export const getAddress = (_data, formatted) => {
  if (formatted) {
    return (
      <>
        <h1 style={{ fontSize: "16px" }}>{_data.address1}</h1>
        <div>
          {_data.address2 || ""}
          {_data.address2 && _data.address3 ? ", " : ""}
          {_data.address3 || ""}
        </div>
        <div>
          {_data.city || ""}
          {_data.city && _data.state ? ", " : ""}
          {_data.state || ""}
          {_data.state && _data.country_name ? ", " : ""}
          {_data.country_name || ""}
        </div>
      </>
    );
  } else {
    let ret = _data.address1;
    if (_data.address2) ret = ret + ", " + _data.address2;
    if (_data.address3) ret = ret + ", " + _data.address3;
    if (_data.city) ret = ret + ", " + _data.city;
    if (_data.country_name) ret = ret + ", " + _data.country_name;
    return ret;
  }
};

export const diffArray = (left, right, compareFunction) =>
  left.filter(
    (leftValue) =>
      !right.some((rightValue) => compareFunction(leftValue, rightValue))
  );

export const getLegoPayload = (data, type) => {
  let payload = {};
  let whereClause = [];
  if (type === "p") {
    whereClause = data.map((item) => {
      return { param_name: item.key, param_value: item.value };
    });
    payload.parameter_mapping = whereClause;
  }
  if (type === "w") {
    whereClause = data.map((item) => {
      return {
        field_name: item.key,
        operator: item.operator,
        values: item.value,
      };
    });
    payload["where"] = whereClause;
  }
  //  whereClause.push({ param_name: 'sr_id', param_value: this.props.match.params.id, param_is_identifier: false });
  if (type === "i") {
    whereClause = data.map((item) => {
      return {
        param_name: item.key,
        param_value: item.value,
        param_is_identifier: false,
      };
    });
    payload["parameter_mapping"] = whereClause;
  }
  return payload;
};

export const getYY = () => new Date().getFullYear();

export const getUUID = () => uuidv4();

export const sanitizeData = (obj) => {
  if (typeof obj === "object") {
    const delKeys = [
      "keywords",
      "canonical_name",
      "updated_by_id",
      "updated_by_name",
      "updated_at",
      "updated_at_dt",
      "created_at_srt",
      "updated_at_srt",
    ];
    let _delKeys = Object.keys(obj).filter(
      (item) => delKeys.includes(item) || item.endsWith("_srt")
    );
    _delKeys.map((key) => delete obj[key]);
  }
  return obj;
};

export const validateCustomFields = (customFields) => {
  let isValid = true;
  if (customFields) {
    customFields = customFields.filter(item => item.status_id === 'ACTIVE').map((c) => {
      delete c.error;
      if (
        ((c.show_by_default && c.is_mandatory) ||
          (c.is_manual_configure && c.is_mandatory)) &&
        (!c.field_data || c.field_data.length <= 0)
      ) {
        isValid = false;
        c.error =
          c.custom_field_type_id === "TEXT_BOX" ||
            c.custom_field_type_id === "NUMERIC_VALUE"
            ? "Please enter value for " + c.name + "."
            : "Please select value for " + c.name + ".";
      }
      return c;
    });
  }
  return { customFields, isValid };
};

export const duplicateCheck = (site_id, field_name, value, object_id, cb) => {
  service.executeQuery(
    {
      path: "utils/duplicate_check",
      query_params: {
        parameter_mapping: [
          { param_name: "site_id", param_value: site_id },
          { param_name: "field_name", param_value: field_name },
          { param_name: "value", param_value: value },
          { param_name: "menu_id", param_value: object_id },
        ],
      },
    },
    (res) => {
      cb({ is_exist: res.data[0] });
    }
  );
};

export const getFormattedDate = (_date) => {
  let date = new Date(_date);
  return date.toISOString().slice(0, 10);
};

export const loadLabelPrintCss = (pf) => {
  try {
    fetch("https://use.typekit.net/tnr6vxu.css").then((t) => {
      t.text().then((style) => {
        let ccss = `
        @media print{@page{size:A4;width:100%!important;height:100%!important}.print-break{page-break-after:always}table,td,th{color:#000!important}h1{font-size:20pt}h2,h3,h4{font-size:12pt}a,article a[href^="#"]:after,blockquote,pre{page-break-inside:avoid}h1,h2,h3,h4,h5,h6,img{page-break-after:avoid;page-break-inside:avoid}dl,ol,ul{page-break-before:avoid}a,a:link,a:visited{background:0 0;color:#520;font-weight:700;text-decoration:underline;text-align:left}a[href^=http]:after{content:" < "attr(href) "> "}a:after>img{content:""}.section{padding-top:0}*{-webkit-print-color-adjust:exact;print-color-adjust:exact}.column.is-narrow,.label-bordered {border: 1px solid #000;border-radius: 10px;.column.is-narrow~.column{display:inline-block}.column.is-6{max-width:50%}.columns:last-child,.field:not(:last-child){margin:0!important}}.label-container{border-color:#333;margin:2mm;font-family:courier-std,monospace!important;font-weight:400;font-size:3mm;color:#000;padding:2.5mm;line-height:normal}b{font-weight:700}.pd-1{padding:.1mm .6mm}.pd-2{padding:2mm}.label-row{display:flex;display:-ms-flexbox;display:-moz-box;display:-webkit-flex;flex-direction:row;line-height:normal}.label-column{flex-grow:1}.direction-column{flex-direction:row!important;justify-content:space-between}.direction-row{flex-direction:column!important;justify-content:space-between}.direction-row>div{padding:1mm 2mm!important}.is-half{width:50%}.label-flex-grow-0{flex-grow:0}.is-wrap{flex-wrap:wrap}.is-nowrap{flex-wrap:nowrap}.table.label-container td{display:block}.table.label-container tr td{align-items:flex-start;background:#fff;display:flex;flex-direction:column;margin-bottom:0}        
        `;
        pf(style + ccss);
      });
    });
  } catch (e) {
    pf("");
  }
};

export const getTomorrowDate = (length) => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const renderColumnHeaders = (columns, id) => {
  return (
    columns && (
      <thead>
        <tr>
          {columns.map((item, index) => {
            return (
              <th scope="col" key={id + index} style={{ minWidth: item.width || "80px" }}>
                {item.label}
              </th>
            );
          })}
        </tr>
      </thead>
    )
  );
};

export const renderColumnWidths = (columns, id) => {
  return (
    columns && (
      <colgroup>
        {columns.map((item, index) => {
          return (
            <col
              key={id + index + "_col"}
              span="1"
              style={{ minWidth: item.width || "80px" }}
            />
          );
        })}
      </colgroup>
    )
  );
};

export const getTemplateFields = (path, object_id, reference_id, cb) => {
  service.executeQuery({
    path,
    query_params: {
      parameter_mapping: [
        { param_name: 'object_id', param_value: object_id },
        { param_name: 'reference_id', param_value: reference_id }
      ]
    }
  }, res => {
    let { custom_Fields, temp_data, footer_coa, footer_datasheet, investigation_coa } = res.data[0].temp_data;
    if (custom_Fields && custom_Fields.length > 0) {
      custom_Fields.map(item => {
        temp_data[item.key] = item.value
        return item;
      })
    }
    let coa_template_data_fields_values = { ...temp_data };
    if (coa_template_data_fields_values.is_released === true && footer_coa && footer_coa.length > 0) {
      footer_coa.map(item => {
        coa_template_data_fields_values[item.key] = item.value
        return item;
      })
    }
    // if (investigation_coa && investigation_coa.length > 0) {
    //   const footerKeys = ['checked_by', 'reviewed_by', 'approved_by', 'authorized_by'];
    //   const footerData = footer_coa.filter(item => footerKeys.includes(item.key));
    //   const isFooterEmpty = footerData.every(item => !item.value);
    //   investigation_coa.forEach(item => {
    //     if (footerKeys.includes(item.key) && coa_template_data_fields_values[item.key] === '' && isFooterEmpty) {
    //       coa_template_data_fields_values[item.key] = item.value;
    //     }
    //   });
    // }
    if (coa_template_data_fields_values.is_released === true && investigation_coa && investigation_coa.length > 0) {
      investigation_coa.forEach(item => {
        const footerKeys = ['checked_by', 'reviewed_by', 'approved_by', 'authorized_by'];
        const footerData = footer_coa.filter(item => footerKeys.includes(item.key));
        const isFooterEmpty = footerData.every(item => !item.value);
        const footerItem = footer_coa.find(footerItem => footerItem.key === item.key);
        investigation_coa.forEach(item => {
          if (footerKeys.includes(item.key) && coa_template_data_fields_values[item.key] === '' && isFooterEmpty) {
            coa_template_data_fields_values[item.key] = item.value;
          }
        });
        if (item.key === 'approved_by') {
          if ((footerItem && item.value1 > footerItem.value1 && item.value)) {
            const details = investigation_coa.find(footerItem => footerItem.key === 'authorized_by')
            if (details === undefined) {
              coa_template_data_fields_values[item.key] = item.value;
            }
          }

        }
        if (item.key === 'authorized_by') {
          if (footerItem && item.value1 > footerItem.value1 && item.value) {
            coa_template_data_fields_values[item.key] = item.value;
          }
        }

      })
    }
    if (coa_template_data_fields_values) {
      if (coa_template_data_fields_values.checked_by === '') {
        coa_template_data_fields_values.checked_by = '--'
      }
      if (coa_template_data_fields_values.reviewed_by === '') {
        coa_template_data_fields_values.reviewed_by = '--'
      }
      if (coa_template_data_fields_values.approved_by === '') {
        coa_template_data_fields_values.approved_by = '--'
      }
      if (coa_template_data_fields_values.authorized_by === '') {
        coa_template_data_fields_values.authorized_by = '--'
      }
    }
    let datasheet_template_data_fields_values = { ...temp_data };
    if (footer_datasheet && footer_datasheet.length > 0) {
      footer_datasheet.map(item => {
        datasheet_template_data_fields_values[item.key] = item.value
        return item;
      })
    }
    cb({ coa_template_data_fields_values, datasheet_template_data_fields_values, investigation_coa });
  });
}

export const getTemplateFields2 = (path, object_id, reference_id, derived_coa_id, cb) => {
  service.executeQuery({
    path,
    query_params: {
      parameter_mapping: [
        { param_name: 'object_id', param_value: object_id },
        { param_name: 'reference_id', param_value: reference_id },
        { param_name: 'derived_coa_id', param_value: derived_coa_id }
      ]
    }
  }, res => {
    let { custom_Fields, temp_data, footer_coa, footer_datasheet, investigation_coa, derived_coa } = res.data[0].temp_data;
    if (custom_Fields && custom_Fields.length > 0) {
      custom_Fields.map(item => {
        temp_data[item.key] = item.value
        return item;
      })
    }
    let coa_template_data_fields_values = { ...temp_data };
    if (coa_template_data_fields_values.is_released === true && footer_coa && footer_coa.length > 0) {
      footer_coa.map(item => {
        coa_template_data_fields_values[item.key] = item.value
        return item;
      })
    }

    if (coa_template_data_fields_values.is_released === true && derived_coa && derived_coa.length > 0) {
      derived_coa.map(item => {
        coa_template_data_fields_values[item.key] = item.value
        return item;
      })
    }
    // if (investigation_coa && investigation_coa.length > 0) {
    //   const footerKeys = ['checked_by', 'reviewed_by', 'approved_by', 'authorized_by'];
    //   const footerData = footer_coa.filter(item => footerKeys.includes(item.key));
    //   const isFooterEmpty = footerData.every(item => !item.value);
    //   investigation_coa.forEach(item => {
    //     if (footerKeys.includes(item.key) && coa_template_data_fields_values[item.key] === '' && isFooterEmpty) {
    //       coa_template_data_fields_values[item.key] = item.value;
    //     }
    //   });
    // }
    if (coa_template_data_fields_values.is_released === true && investigation_coa && investigation_coa.length > 0) {
      investigation_coa.forEach(item => {
        const footerKeys = ['checked_by', 'reviewed_by', 'approved_by', 'authorized_by'];
        const footerData = footer_coa.filter(item => footerKeys.includes(item.key));
        const isFooterEmpty = footerData.every(item => !item.value);
        const footerItem = footer_coa.find(footerItem => footerItem.key === item.key);
        investigation_coa.forEach(item => {
          if (footerKeys.includes(item.key) && coa_template_data_fields_values[item.key] === '' && isFooterEmpty) {
            coa_template_data_fields_values[item.key] = item.value;
          }
        });
        if (item.key === 'approved_by') {
          if ((footerItem && item.value1 > footerItem.value1 && item.value)) {
            const details = investigation_coa.find(footerItem => footerItem.key === 'authorized_by')
            if (details === undefined) {
              coa_template_data_fields_values[item.key] = item.value;
            }
          }

        }
        if (item.key === 'authorized_by') {
          if (footerItem && item.value1 > footerItem.value1 && item.value) {
            coa_template_data_fields_values[item.key] = item.value;
          }
        }

      })
    }
    if (coa_template_data_fields_values) {
      if (coa_template_data_fields_values.checked_by === '') {
        coa_template_data_fields_values.checked_by = '--'
      }
      if (coa_template_data_fields_values.reviewed_by === '') {
        coa_template_data_fields_values.reviewed_by = '--'
      }
      if (coa_template_data_fields_values.approved_by === '') {
        coa_template_data_fields_values.approved_by = '--'
      }
      if (coa_template_data_fields_values.authorized_by === '') {
        coa_template_data_fields_values.authorized_by = '--'
      }
      if (coa_template_data_fields_values.derived_prepared_by === '') {
        coa_template_data_fields_values.derived_prepared_by = '--'
      }
      if (coa_template_data_fields_values.sign_off_1 === '') {
        coa_template_data_fields_values.sign_off_1 = '--'
      }
      if (coa_template_data_fields_values.sign_off_2 === '') {
        coa_template_data_fields_values.sign_off_2 = '--'
      }
      if (coa_template_data_fields_values.sign_off_3 === '') {
        coa_template_data_fields_values.sign_off_3 = '--'
      }
      if (coa_template_data_fields_values.sign_off_4 === '') {
        coa_template_data_fields_values.sign_off_4 = '--'
      }
    }
    let datasheet_template_data_fields_values = { ...temp_data };
    if (footer_datasheet && footer_datasheet.length > 0) {
      footer_datasheet.map(item => {
        datasheet_template_data_fields_values[item.key] = item.value
        return item;
      })
    }
    cb({ coa_template_data_fields_values, datasheet_template_data_fields_values, investigation_coa });
  });
}


export const getTitleText = (me, text) => {
  const { app_configs } = me;
  return (app_configs ? "Lab Iconics "+ app_configs.product : "LIMS-ELN") + " - " + text;
};


export const PrintModal = ({ htmlContent }) => {
  const [showPrintOptions, setShowPrintOptions] = useState(true);

  const handlePrint = (orientation) => {
    // Concatenate all CSS styles needed for print
    const css = `${matlIconCss}${styleCss}${mainCss}${printCss}`;
    const rootClass = document.getElementById("root").className;
  
    // Create the print-ready HTML with styling
    const printHtml = `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            ${css}
            @media print {
              @page { size: ${orientation}; }
            }
          </style>
        </head>
        <body>
          <div id="root" class="${rootClass}">
            ${htmlContent}
          </div>
        </body>
      </html>
    `;
  
    // Create an iframe to hold the print document
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);
  
    // Access the iframe's window and document to load the content
    const winPrint = iframe.contentWindow;
    winPrint.document.open();
    winPrint.document.write(printHtml);
    winPrint.document.close();
    
    // Focus on the iframe and trigger print
    winPrint.focus();
    setTimeout(() => {
      winPrint.print();
      winPrint.close();
      iframe.remove();
    }, 250); // Adjust if necessary for complex content
  
    setShowPrintOptions(false); // Hide the print options after print is triggered
  };
  

  return (
    showPrintOptions && (
      <div className="popup">
        <div className="modal is-active">
          <div className=""></div>
          <div className="modal-content">
            <div className="box">
              <h4 className="title">Select Print Orientation</h4>
              <div className="buttons">
                <button className="button is-primary" onClick={() => handlePrint('portrait')}>Portrait</button>
                <button className="button is-info" onClick={() => handlePrint('landscape')}>Landscape</button>
              </div>
            </div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={() => setShowPrintOptions(false)}></button>
        </div>
      </div>
    )
  );
};

export const print = (html) => {
  const printRoot = document.createElement('div');
  document.body.appendChild(printRoot);
  const root = createRoot(printRoot);
  root.render(<PrintModal htmlContent={html} />);
};


// export const print = (html) => {
//   let css = matlIconCss + styleCss + mainCss + printCss;
//   let rootClass = document.getElementById("root").className;
//   let printHtml = `<!DOCTYPE html><html><head><style>` + css + `</style></head><body><div id="root" class="` + rootClass + `">` + html + `</div></body></html>`;
//   let iframe = document.createElement("iframe");
//   iframe.style = "display:none";
//   document.body.appendChild(iframe);
//   let winPrint = iframe.contentWindow;
//   winPrint.document.open();
//   winPrint.document.write(printHtml);
//   winPrint.document.close();
//   winPrint.focus();
//   setTimeout(() => {
//     winPrint.print();
//     winPrint.close();
//     iframe.remove();
//   }, 250);
// }

export const removeItem = (arr, value) => {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export const getString = (str) => {
  let ret = str;
  if (str === "INSERT") ret = "CREATE";
  return ret;
}

export const trimDecimalPlaces = (number, maxDecimal) => {
  let ret = number;
  let number_str = number + '';
  let decimal = undefined;
  if (number_str.indexOf(".") > -1) decimal = number_str.split('.')[1].length || 0;
  if (decimal && decimal > 6) ret = number.toFixed(maxDecimal);
  return ret;
}

export const getCustomFields = (reference_id, site_id, menu_id, cb) => {
  service.executeQuery({
    path: 'utils/get_custom_fields',
    query_params: {
      parameter_mapping: [
        { param_name: 'reference_id', param_value: reference_id },
        { param_name: 'site_id', param_value: site_id },
        { param_name: 'menu_id', param_value: menu_id }
      ]
    }
  }, res => {
    let customFields = res.data;
    let optional_fields = res.data.filter(item => !item.show_by_default)
    cb({ customFields, optional_fields });
  });
}
export const getCustomFieldsView = (reference_id, site_id, menu_id, cb) => {
  service.executeQuery({
    path: 'utils/get_custom_fieldsView',
    query_params: {
      parameter_mapping: [
        { param_name: 'reference_id', param_value: reference_id },
        { param_name: 'site_id', param_value: site_id },
        { param_name: 'menu_id', param_value: menu_id }
      ]
    }
  }, res => {
    let customFields = res.data;
    let optional_fields = res.data.filter(item => !item.show_by_default)
    cb({ customFields, optional_fields });
  });
}


export const getCustomFieldsview = (reference_id, site_id, menu_id, cb) => {
  service.executeQuery({
    path: 'utils/get_custom_fields2',
    query_params: {
      parameter_mapping: [
        { param_name: 'reference_id', param_value: reference_id },
        { param_name: 'site_id', param_value: site_id },
        { param_name: 'menu_id', param_value: menu_id }
      ]
    }
  }, res => {
    let customFields = res.data;
    let optional_fields = res.data.filter(item => !item.show_by_default)
    cb({ customFields, optional_fields });
  });
}

export const sortArray = (arr, prop, typeOf, sortOrder) => {
  if (arr && arr.length > 0) {
    arr.sort((ob1, ob2) => {
      if (typeOf === 'number' && ob1[prop] && ob2[prop]) {
        return (sortOrder === 'asc') ? (ob1[prop] - ob2[prop]) : (ob2[prop] - ob1[prop]);
      } else if (typeOf === 'string' && ob1[prop] && ob2[prop]) {
        return (sortOrder === 'asc') ? ob1[prop]?.localeCompare(ob2[prop]) : ob2[prop]?.localeCompare(ob1[prop]);
      }
      return '';
    })
  }
  return arr;
};

export const checkApproval = (menu_id, site_id, cb) => {
  service.executeQuery({
    path: 'utils/check_approval',
    query_params: {
      parameter_mapping: [
        { param_name: 'menu_id', param_value: menu_id },
        { param_name: 'site_id', param_value: site_id }
      ]
    }
  }, res => {
    let is_active_approval = res && res.data[0] ? res.data[0].signoffs_active === 1 ? true : false : false
    cb({ is_active_approval });
  });
}